const carouselOpt = (attributes, initialSlide, isBackend = false) => {
	const { columns, columnGap, isLoop, speed, autoplayOptions } = attributes.carouselOptions;

	return {
		// Optional parameters
		direction: 'horizontal',
		initialSlide,
		slidesPerView: columns?.mobile,
		breakpoints: {
			// when window width is >= 576px
			576: { slidesPerView: columns?.tablet },
			// when window width is >= 768px
			768: { slidesPerView: columns?.desktop },
		},
		spaceBetween: columnGap,
		loop: isLoop,
		allowTouchMove: isBackend ? false : true,
		grabCursor: isBackend ? false : true,
		autoplay: autoplayOptions?.enabled && !isBackend ? { delay: 1500 } : false,
		speed: speed * 1000,
		effect: 'slide',
		allowSlideNext: true,
		allowSlidePrev: true,
		autoHeight: false,
		notificationClass: null,

		// Controllers
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			dynamicBullets: false
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	}
}
export default carouselOpt;