import { getBackgroundCSS, getSpaceCSS } from '../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { height = '250px', background, padding, pageSize, pageColor, prevNextSize, prevNextColor } = attributes;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			#bicbCarousel-${clientId}{
				${getBackgroundCSS(background)}
				padding: ${getSpaceCSS(padding)};
			}
			#bicbCarousel-${clientId} .bicbCarousel .carousel{
				height: ${height};
			}
			#bicbCarousel-${clientId} .bicbCarousel .swiper-pagination .swiper-pagination-bullet{
				background: ${pageColor};
				width: ${pageSize};
				height: ${pageSize};
			}
			#bicbCarousel-${clientId} .bicbCarousel .swiper-button-prev,
			#bicbCarousel-${clientId} .bicbCarousel .swiper-button-next{
				color: ${prevNextColor};
			}
			#bicbCarousel-${clientId} .bicbCarousel .swiper-button-prev::after,
			#bicbCarousel-${clientId} .bicbCarousel .swiper-button-next::after {
				font-size: ${prevNextSize};
			}
			`.replace(/\s+/g, ' ')
		}} />
	</>
}
export default Style;